/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Picker from 'rc-picker';
import 'rc-picker/assets/index.css';
import enUS from 'rc-picker/lib/locale/en_US';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import TimePicker from 'rc-time-picker';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { PiCalendarBlankBold, PiClockBold } from 'react-icons/pi';
import Button from '../../../shared/Button';
import Filters from '../../../Filters';
import { FILTER_TYPES, TAB_IDS } from './constants';
import './custom-styles.scss';
import FiltersColumn from '../../../FiltersColumn';
import FilterTextField from '../../../FilterTextField';

function AvailableFilters({
  currentlyAppliedFilters,
  appointmentTypesData,
  availabilityData,
  providersData,
  daysData,
  facilitiesData,
  setFilter,
  statesData,
  activeTabId,
  isLoading,
  testID,
}) {
  const getStateChangeHandler = (type) => (selectedItem) => setFilter(type, selectedItem);
  const getTextChangeHandler = (type) => (value) => setFilter(type, value);

  return (
    <div
      className="border border-gray-300 rounded bg-white bg-opacity-50 mt-6 flex flex-row divide-x divide-gray-300 relative"
      data-testid={`available-filters-${testID}`}
    >
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 flex items-center justify-center z-10">
          <CircularProgress size={32} className="text-gray-700" />
        </div>
      )}
      {(activeTabId === TAB_IDS.SCHEDULED || activeTabId === TAB_IDS.BOOK_APPOINTMENT) && (
        <>
          <FiltersColumn>
            <Filters
              label="Appointment Type"
              type={FILTER_TYPES.appointmentType}
              data={appointmentTypesData}
              stateChanger={getStateChangeHandler(FILTER_TYPES.appointmentType)}
              testID={`appointment-type-${testID}`}
              placeholder="Search Appointment Type"
              value={currentlyAppliedFilters[FILTER_TYPES.appointmentType]}
              allowEmpty
            />
            <Filters
              label="State"
              type={FILTER_TYPES.state}
              data={statesData}
              stateChanger={getStateChangeHandler(FILTER_TYPES.state)}
              testID={`states-${testID}`}
              placeholder="Search State"
              value={currentlyAppliedFilters[FILTER_TYPES.state]}
              allowEmpty
            />
            <Filters
              label="Facility"
              type={FILTER_TYPES.facility}
              data={facilitiesData}
              stateChanger={getStateChangeHandler(FILTER_TYPES.facility)}
              testID={`facilities-${testID}`}
              placeholder="Search Facility"
              value={currentlyAppliedFilters[FILTER_TYPES.facility]}
              allowEmpty
            />
          </FiltersColumn>
          <FiltersColumn>
            <Filters
              label="Day of the week"
              type={FILTER_TYPES.dayOfWeek}
              data={daysData}
              stateChanger={getStateChangeHandler(FILTER_TYPES.dayOfWeek)}
              testID={`days-${testID}`}
              placeholder="Search Day"
              value={currentlyAppliedFilters[FILTER_TYPES.dayOfWeek]}
              allowEmpty
            />
            <Filters
              label="Staff Member"
              type={FILTER_TYPES.provider}
              data={providersData}
              stateChanger={getStateChangeHandler(FILTER_TYPES.provider)}
              testID={`facilities-${testID}`}
              placeholder="Search Staff"
              value={currentlyAppliedFilters[FILTER_TYPES.provider]}
              allowEmpty
            />
            <FilterTextField
              name="member-search-input"
              id="member-search-input"
              label="Member"
              placeholder="Search Members by Name or MRN"
              value={currentlyAppliedFilters[FILTER_TYPES.member]}
              onChange={getTextChangeHandler(FILTER_TYPES.member)}
            />
          </FiltersColumn>
          <FiltersColumn>
            <div className="flex flex-row picker-container">
              <label className="filter-label">
                From Time
                <TimePicker
                  showSecond={false}
                  placeholder="Select time"
                  value={currentlyAppliedFilters[FILTER_TYPES.fromTime]}
                  onChange={getStateChangeHandler(FILTER_TYPES.fromTime)}
                  testID={`from-time-${testID}`}
                  use12Hours
                  inputReadOnly
                  inputIcon={<PiClockBold />}
                />
              </label>
              <label className="filter-label">
                To Time
                <TimePicker
                  showSecond={false}
                  placeholder="Select time"
                  value={currentlyAppliedFilters[FILTER_TYPES.toTime]}
                  onChange={getStateChangeHandler(FILTER_TYPES.toTime)}
                  testID={`to-time-${testID}`}
                  use12Hours
                  inputReadOnly
                  inputIcon={<PiClockBold />}
                />
              </label>
              <Button
                type="button"
                aria-label="Clear Time"
                classes="flex w-7 h-7"
                isTertiary
                disabled={
                  !currentlyAppliedFilters[FILTER_TYPES.fromTime] && !currentlyAppliedFilters[FILTER_TYPES.toTime]
                }
                onClick={(e) => {
                  setFilter(FILTER_TYPES.fromTime, null);
                  setFilter(FILTER_TYPES.toTime, null);
                  e.target.blur();
                }}
              >
                <CloseIcon />
              </Button>
            </div>
            <div className="flex flex-row picker-container">
              <label className="filter-label">
                From Date
                <Picker
                  type="date"
                  locale={enUS}
                  generateConfig={momentGenerateConfig}
                  value={currentlyAppliedFilters[FILTER_TYPES.fromDate]}
                  onChange={getStateChangeHandler(FILTER_TYPES.fromDate)}
                  testID={`from-date-${testID}`}
                  placeholder="Select date"
                  suffixIcon={<PiCalendarBlankBold />}
                />
              </label>
              <label className="filter-label">
                To Date
                <Picker
                  type="date"
                  locale={enUS}
                  generateConfig={momentGenerateConfig}
                  value={currentlyAppliedFilters[FILTER_TYPES.toDate]}
                  onChange={getStateChangeHandler(FILTER_TYPES.toDate)}
                  testID={`to-date-${testID}`}
                  placeholder="Select date"
                  suffixIcon={<PiCalendarBlankBold />}
                />
              </label>
              <Button
                type="button"
                aria-label="Clear Date"
                classes="flex w-7 h-7"
                isTertiary
                disabled={
                  !currentlyAppliedFilters[FILTER_TYPES.fromDate] && !currentlyAppliedFilters[FILTER_TYPES.toDate]
                }
                onClick={(e) => {
                  setFilter(FILTER_TYPES.fromDate, null);
                  setFilter(FILTER_TYPES.toDate, null);
                  e.target.blur();
                }}
              >
                <CloseIcon />
              </Button>
            </div>
            {activeTabId === TAB_IDS.BOOK_APPOINTMENT && (
              <Filters
                label="Availability"
                type={FILTER_TYPES.availability}
                data={availabilityData}
                stateChanger={getStateChangeHandler(FILTER_TYPES.availability)}
                testID={`availability-${testID}`}
                placeholder="Search Availability"
                value={currentlyAppliedFilters[FILTER_TYPES.availability]}
                allowEmpty
              />
            )}
          </FiltersColumn>
        </>
      )}
      {activeTabId === TAB_IDS.STAFF_MEMBER_FACILITIES && (
        <>
          <FiltersColumn>
            <Filters
              label="Staff Member"
              type={FILTER_TYPES.provider}
              data={providersData}
              stateChanger={getStateChangeHandler(FILTER_TYPES.provider)}
              testID={`staff-${testID}`}
              placeholder="Search Staff"
              value={currentlyAppliedFilters[FILTER_TYPES.provider]}
              allowEmpty
            />
          </FiltersColumn>
          <FiltersColumn>
            <Filters
              label="Facility"
              type={FILTER_TYPES.facility}
              data={facilitiesData}
              stateChanger={getStateChangeHandler(FILTER_TYPES.facility)}
              testID={`facilities-${testID}`}
              placeholder="Search Facility"
              value={currentlyAppliedFilters[FILTER_TYPES.facility]}
              allowEmpty
            />
          </FiltersColumn>
          <FiltersColumn>
            <Filters
              label="State"
              type={FILTER_TYPES.state}
              data={statesData}
              stateChanger={getStateChangeHandler(FILTER_TYPES.state)}
              testID={`states-${testID}`}
              placeholder="Search State"
              value={currentlyAppliedFilters[FILTER_TYPES.state]}
              allowEmpty
            />
          </FiltersColumn>
          <FiltersColumn>
            <Filters
              label="Appointment Type"
              type={FILTER_TYPES.appointmentType}
              data={appointmentTypesData}
              stateChanger={getStateChangeHandler(FILTER_TYPES.appointmentType)}
              testID={`appointment-type-${testID}`}
              placeholder="Search Appointment Type"
              value={currentlyAppliedFilters[FILTER_TYPES.appointmentType]}
              allowEmpty
            />
          </FiltersColumn>
        </>
      )}
    </div>
  );
}

AvailableFilters.propTypes = {
  currentlyAppliedFilters: PropTypes.object.isRequired,
  appointmentTypesData: PropTypes.array.isRequired,
  availabilityData: PropTypes.array.isRequired,
  providersData: PropTypes.array.isRequired,
  daysData: PropTypes.array.isRequired,
  facilitiesData: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
  statesData: PropTypes.array.isRequired,
  activeTabId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  testID: PropTypes.string.isRequired,
};

export default AvailableFilters;
