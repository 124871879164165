import React from 'react';
import PropTypes from 'prop-types';
import { PiArrowSquareOutBold } from 'react-icons/pi';
import classNames from 'classnames';
import ValueRenderer from './ValueRenderer';
import { textPropTypes, valuesPropTypes, valuePropTypes, valueOptionsPropTypes } from './types';
import { defaultValueOptions, defaultSubtextOptions, defaultSuffixOptions } from './constants';
import { transformValue, generateValueOptionsStyles } from './helpers';

function MemberDetailsRow({ label, value, values, valueOptions, subtext, suffixText, tooltip, url }) {
  return (
    <div className="flex flex-col md:flex-row gap-2 pb-2 border-b border-dotted border-gray-400 last:border-b-0 last:pb-0">
      <div className="w-1/5 min-w-100 flex-shrink-0">
        <span className="text-xs text-gray-700 font-medium uppercase">{label}</span>
      </div>

      <div className="flex-grow flex gap-1">
        {url && (
          <a target="_blank" href={url} rel="noreferrer">
            <PiArrowSquareOutBold className="w-5 h-5 mr-1" />
          </a>
        )}

        <div className="flex flex-col gap-1">
          {values?.map((row, i) => (
            // index key is fine here since the data is static
            // eslint-disable-next-line react/no-array-index-key
            <ValueRenderer key={i} {...row} />
          ))}

          <ValueRenderer value={value} valueOptions={valueOptions} suffixText={suffixText} tooltip={tooltip} />

          {subtext &&
            (Array.isArray(subtext) ? (
              subtext.map((text, i) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={classNames(
                    'whitespace-pre-wrap',
                    generateValueOptionsStyles(text?.valueOptions || defaultSubtextOptions)
                  )}
                >
                  {transformValue(text?.value)}
                </span>
              ))
            ) : (
              <span
                className={classNames(
                  'whitespace-pre-wrap',
                  generateValueOptionsStyles(subtext?.valueOptions || defaultSubtextOptions)
                )}
              >
                {transformValue(subtext?.value)}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
}

export const MemberDetailsRowPropTypes = {
  label: PropTypes.string.isRequired,
  values: valuesPropTypes,
  value: valuePropTypes,
  valueOptions: valueOptionsPropTypes,
  subtext: PropTypes.oneOfType([textPropTypes, PropTypes.arrayOf(textPropTypes)]),
  suffixText: PropTypes.shape({
    value: valuePropTypes.isRequired,
    valueOptions: valueOptionsPropTypes,
  }),
  tooltip: PropTypes.string,
  url: PropTypes.string,
};

MemberDetailsRow.propTypes = MemberDetailsRowPropTypes;

MemberDetailsRow.defaultProps = {
  values: null,
  value: null,
  valueOptions: defaultValueOptions,
  subtext: {
    value: null,
    valueOptions: defaultSubtextOptions,
  },
  suffixText: {
    value: null,
    valueOptions: defaultSuffixOptions,
  },
  tooltip: null,
  url: null,
};

export default MemberDetailsRow;
