import React from 'react';
import PropTypes from 'prop-types';

// HELPERS
import { parsePhoneString } from '../../helpers/AssortedHelpers';

// COMPONENTS
import Header from '../shared/Header';
import ListItem from '../shared/ListItem';
import DetailsDiv from '../shared/DetailsDiv';
import LocationTag from '../shared_dashboard/LocationTag';
import { DEFAULT_DATE_WITH_WEEKDAY_FORMAT, DEFAULT_TIME_FORMAT } from '../../helpers/DateFormatter';
import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';

const formatDateWithTimezone = (date) => {
  if (!date) return 'No Upcoming Group';

  const [dateWithOffset, timezone] = convertToSupportedTimeZone(date);
  return `${dateWithOffset.format(DEFAULT_DATE_WITH_WEEKDAY_FORMAT)} ${dateWithOffset.format(DEFAULT_TIME_FORMAT)} ${timezone?.abbrev}`;
};

function ExpandViewMemberDetails({ data, memberGroup, memberEmpanelment, secondaryGroupTypeFeatureFlag }) {
  const phone = parsePhoneString(data?.member_phone);

  const formattedGroupDate = formatDateWithTimezone(memberGroup?.date_time);
  const formattedEmpanelmentDate = formatDateWithTimezone(memberEmpanelment?.date_time);

  return (
    <div className="m-2">
      {/* HEADER - MEMBER NAME */}
      <Header text={data?.member_name} style={{ color: '#217886', fontWeight: 500 }} class={null} />

      {/* MRN and PHONE ROW */}
      <div className="flex">
        <div className="flex-1">
          <DetailsDiv header="MRN" subText={data?.member_mrn} />
        </div>

        <div className="flex-1">
          <DetailsDiv header="PHONE NUMBER" subText={phone} />
        </div>
      </div>

      {/* CURRENT GROUP */}
      <DetailsDiv header="NEXT SCHEDULED GROUP" customSubClass="flex" subText={formattedGroupDate}>
        {memberGroup?.location_name && <LocationTag preloadedData={memberGroup?.location_name} />}
      </DetailsDiv>

      {/* EMPANELMENT */}
      <DetailsDiv header="EMPANELMENT" customStyle={{ marginTop: '35px' }}>
        {memberEmpanelment?.id ? (
          <>
            <ListItem label="Modality" text={memberEmpanelment?.modality} />
            {secondaryGroupTypeFeatureFlag ? (
              <>
                <ListItem label="Clinical Model" text={memberEmpanelment?.group_type} />
                {memberEmpanelment?.secondary_group_types && (
                  <ListItem label="Other Information" text={memberEmpanelment?.secondary_group_types} />
                )}
              </>
            ) : (
              <ListItem label="Group Type" text={memberEmpanelment?.group_type} />
            )}
            <ListItem label="Office Manager" text={memberEmpanelment?.office_manager} />
            <ListItem label="Counselor" text={memberEmpanelment?.counselor} />
            <ListItem label="Provider" text={memberEmpanelment?.provider} />
            {formattedEmpanelmentDate}
            {memberEmpanelment?.location_name && <LocationTag preloadedData={memberEmpanelment?.location_name} />}
          </>
        ) : (
          <>Not Empaneled</>
        )}
      </DetailsDiv>
    </div>
  );
}

ExpandViewMemberDetails.propTypes = {
  data: PropTypes.string.isRequired,
  memberGroup: PropTypes.object.isRequired,
  memberEmpanelment: PropTypes.object.isRequired,

  secondaryGroupTypeFeatureFlag: PropTypes.bool,
};

ExpandViewMemberDetails.defaultProps = {
  secondaryGroupTypeFeatureFlag: false,
};

export default ExpandViewMemberDetails;
