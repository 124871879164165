import PropTypes from 'prop-types';

export const ActionListTaskPropTypes = PropTypes.shape({
  assignment_date: PropTypes.string,
  created_at: PropTypes.string,
  due_date: PropTypes.string,
  last_action: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.string,
  how_to_resolve_copy: PropTypes.string,
  helper_link: PropTypes.string,
});

export const ActionListTaskHistoryPropTypes = PropTypes.shape({
  id: PropTypes.number,
  description: PropTypes.string,
  created_at: PropTypes.string,
  details: PropTypes.string,
});
