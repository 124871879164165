import PropTypes from 'prop-types';
import { FONT_COLOR, FONT_SIZE, FONT_TYPE, FONT_WEIGHT } from './constants';
import { ColoredLabelPropTypes } from './ColoredLabel';

export const AppointmentAttendancePropTypes = {
  dateTime: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  attendanceStatus: ColoredLabelPropTypes.isRequired,
  screenings: PropTypes.shape({
    fent: PropTypes.shape(ColoredLabelPropTypes),
    uds: PropTypes.shape({
      bup: PropTypes.shape(ColoredLabelPropTypes),
      otherSubstances: PropTypes.arrayOf(PropTypes.shape(ColoredLabelPropTypes)),
    }),
  }).isRequired,
};

export const valuePropTypes = PropTypes.oneOf([
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    template: PropTypes.string.isRequired,
    variables: PropTypes.objectOf(
      PropTypes.shape({
        algorithm: PropTypes.string.isRequired,
        parameters: PropTypes.objectOf(PropTypes.any),
      })
    ),
  }),
]);

export const valueOptionsPropTypes = PropTypes.shape({
  size: PropTypes.oneOf(Object.values(FONT_SIZE)),
  fontType: PropTypes.oneOf(Object.values(FONT_TYPE)),
  fontWeight: PropTypes.oneOf(Object.values(FONT_WEIGHT)),
  color: PropTypes.oneOf(Object.values(FONT_COLOR)),
});

export const textPropTypes = PropTypes.shape({
  value: valuePropTypes.isRequired,
  valueOptions: valueOptionsPropTypes,
});

export const suffixTextPropTypes = PropTypes.shape({
  value: valuePropTypes.isRequired,
  valueOptions: valueOptionsPropTypes,
});

export const valuesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: valuePropTypes.isRequired,
    valueOptions: valueOptionsPropTypes,
    suffixText: suffixTextPropTypes,
    tooltip: PropTypes.string,
  })
);
