import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from '../shared/Tooltip';
import { valuePropTypes, valueOptionsPropTypes, suffixTextPropTypes } from './types';
import { transformValue, generateValueOptionsStyles } from './helpers';
import { defaultSuffixOptions, defaultValueOptions } from './constants';

function ValueRenderer({ value, valueOptions, suffixText, tooltip }) {
  const hasValue = value !== null && value !== undefined;
  if (!hasValue && !suffixText && !tooltip) return null;

  return (
    <div className="flex gap-1 items-center">
      {hasValue && (
        <span
          className={classNames('whitespace-pre-wrap', generateValueOptionsStyles(valueOptions || defaultValueOptions))}
        >
          {transformValue(value)}
        </span>
      )}
      {suffixText && (
        <span className={generateValueOptionsStyles(suffixText?.valueOptions || defaultSuffixOptions)}>
          {transformValue(suffixText?.value)}
        </span>
      )}
      {tooltip && <Tooltip text={tooltip} style={{ alignSelf: 'start', paddingTop: '2px' }} isSecondaryVariant />}
    </div>
  );
}

ValueRenderer.propTypes = {
  value: valuePropTypes.isRequired,
  valueOptions: valueOptionsPropTypes,
  suffixText: suffixTextPropTypes,
  tooltip: PropTypes.string,
};

ValueRenderer.defaultProps = {
  valueOptions: null,
  suffixText: null,
  tooltip: null,
};

export default ValueRenderer;
