import { triggerAlgorithm } from './helpers/memberDetailsAlgorithms';
import { FONT_SIZE, FONT_TYPE, FONT_WEIGHT, FONT_COLOR } from './constants';

export function generateValueOptionsStyles(valueOptions) {
  const { size, fontType, fontWeight, color } = valueOptions || {};
  const classes = [];

  switch (size) {
    case FONT_SIZE.SMALL:
      classes.push('text-xs');
      break;
    case FONT_SIZE.STANDARD:
    default:
      break;
  }

  switch (fontType) {
    case FONT_TYPE.ITALICS:
      classes.push('italic');
      break;
    case FONT_TYPE.STANDARD:
    default:
      break;
  }

  switch (fontWeight) {
    case FONT_WEIGHT.BOLD:
      classes.push('font-bold');
      break;
    case FONT_WEIGHT.STANDARD:
    default:
      break;
  }

  switch (color) {
    case FONT_COLOR.SUCCESS:
      classes.push('text-positive-700');
      break;
    case FONT_COLOR.WARNING:
      classes.push('text-warning-500');
      break;
    case FONT_COLOR.ERROR:
      classes.push('text-error-700');
      break;
    case FONT_COLOR.STANDARD:
    default:
      break;
  }

  return classes.join(' ');
}

export function transformValue(value) {
  if (!value && value !== 0) {
    return '';
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return value;
  }

  const { template, variables } = value || {};

  if (!template || !variables) {
    return '';
  }

  return template?.replace(/{{\s*(\w+)\s*}}/g, (_, key) => {
    const { algorithm, parameters } = variables[key];
    return triggerAlgorithm(algorithm, parameters);
  });
}
