import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// HELPERS
import { capitalizeFirst } from '../../helpers/AssortedHelpers';

// COMPONENTS
import DetailsDiv from '../shared/DetailsDiv';
import ExpandButton from '../shared/ExpandButton';
import ExpandViewTaskHistory from './ExpandViewTaskHistory';
import { formatDailyTaskDate } from './utils/utils';
import { ActionListTaskHistoryPropTypes, ActionListTaskPropTypes } from './utils/types';

function ExpandViewOtherTaskDetails({ task, histories, staffName }) {
  const [taskHistories, setTaskHistories] = useState(null);

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    setTaskHistories(histories);
  }, [histories]);

  function renderFirstHistory(history, index) {
    if (index > 0) {
      return <></>;
    }
    return <ExpandViewTaskHistory history={history} />;
  }

  function renderRemainingHistories(history, index) {
    if (!showMore) {
      return <></>;
    }
    if (index === 0) {
      return <></>;
    }
    return <ExpandViewTaskHistory history={history} header="" />;
  }

  return (
    <div>
      {/* TASK DETAILS */}
      <div className="flex">
        <DetailsDiv header="ASSIGNED" subText={formatDailyTaskDate(task?.assignment_date)} customClass="flex-1" />
        <DetailsDiv header="CREATED" subText={formatDailyTaskDate(task?.created_at)} customClass="flex-1" />
        <DetailsDiv header="DUE" subText={formatDailyTaskDate(task?.due_date)} customClass="flex-1" />
        <DetailsDiv
          header="STATUS"
          subText={capitalizeFirst(task?.last_action) || capitalizeFirst(task?.status)}
          customClass="flex-1"
        />
      </div>

      <div className="flex">
        <DetailsDiv header="TASK TYPE" subText={task?.task_type} customClass="flex-1" />
        <DetailsDiv header="ASSIGNEE" subText={staffName} customClass="flex-1" />
      </div>

      {/* TASK HISTORIES */}
      {taskHistories?.map((history, index) => (
        <>{renderFirstHistory(history, index)}</>
      ))}

      <ExpandButton
        show={taskHistories?.length > 1}
        text={`${taskHistories?.length ? taskHistories.length - 1 : 0} MORE UPDATES`}
        onShowMore={toggleShowMore}
      />

      {taskHistories?.map((history, index) => (
        <>{renderRemainingHistories(history, index)}</>
      ))}
    </div>
  );
}

ExpandViewOtherTaskDetails.propTypes = {
  task: ActionListTaskPropTypes.isRequired,
  histories: PropTypes.arrayOf(ActionListTaskHistoryPropTypes).isRequired,
  staffName: PropTypes.string.isRequired,
};

export default ExpandViewOtherTaskDetails;
