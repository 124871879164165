import React from 'react';
import PropTypes from 'prop-types';

// HELPERS
import { DEFAULT_DATETIME_FORMAT } from '../../helpers/DateFormatter';

// COMPONENTS
import DetailsDiv from '../shared/DetailsDiv';
import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';
import { ActionListTaskHistoryPropTypes } from './utils/types';

const formatDate = (date) => {
  if (!date) return '';

  const [dateWithOffset, timezone] = convertToSupportedTimeZone(date);
  return `${dateWithOffset.format(DEFAULT_DATETIME_FORMAT)} ${timezone.abbrev}`;
};

function ExpandViewTaskHistory({ header, history }) {
  return (
    <div className="flex" key={history?.id}>
      <DetailsDiv header={header} subText={history?.description} customClass="container p-0">
        <div className="text-sm font-light text-gray-700">{formatDate(history?.created_at)}</div>

        {history?.details && (
          <div style={{ backgroundColor: '#f5f8fa', borderRadius: '5px' }} className="mt-3 p-2">
            {history?.details}
          </div>
        )}
      </DetailsDiv>
    </div>
  );
}

ExpandViewTaskHistory.propTypes = {
  header: PropTypes.string,
  history: ActionListTaskHistoryPropTypes.isRequired,
};

ExpandViewTaskHistory.defaultProps = {
  header: 'TASK HISTORY',
};

export default ExpandViewTaskHistory;
