import { TAG_STATUS } from '../shared/StatusTag';

export const MemberDetailsComponentType = {
  MEDICAL: 'MEDICAL',
  CLINICAL: 'CLINICAL',
  DEMOGRAPHICS: 'DEMOGRAPHICS',
};

export const FONT_SIZE = {
  STANDARD: 'STANDARD',
  SMALL: 'SMALL',
};

export const FONT_TYPE = {
  STANDARD: 'STANDARD',
  ITALICS: 'ITALICS',
};

export const FONT_WEIGHT = {
  STANDARD: 'STANDARD',
  BOLD: 'BOLD',
};

export const FONT_COLOR = TAG_STATUS;

export const defaultValueOptions = {
  size: FONT_SIZE.STANDARD,
  fontType: FONT_TYPE.STANDARD,
  fontWeight: FONT_WEIGHT.STANDARD,
  color: FONT_COLOR.STANDARD,
};

export const defaultSubtextOptions = {
  size: FONT_SIZE.SMALL,
  fontType: FONT_TYPE.STANDARD,
  fontWeight: FONT_WEIGHT.STANDARD,
  color: FONT_COLOR.STANDARD,
};

export const defaultSuffixOptions = {
  size: FONT_SIZE.STANDARD,
  fontType: FONT_TYPE.ITALICS,
  fontWeight: FONT_WEIGHT.STANDARD,
  color: FONT_COLOR.STANDARD,
};
