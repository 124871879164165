import { DEFAULT_DATE_FORMAT } from '../../../helpers/DateFormatter';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';

export const formatDailyTaskDate = (date) => {
  if (!date) return '';

  const [dateWithOffset] = convertToSupportedTimeZone(date);
  return dateWithOffset.format(DEFAULT_DATE_FORMAT);
};

export const calculateOverdue = (dueDate) => {
  if (!dueDate) {
    return null;
  }

  const [today] = convertToSupportedTimeZone(new Date());
  const [dueDateWithOffset] = convertToSupportedTimeZone(dueDate);

  const daysDifference = today.startOf('day').diff(dueDateWithOffset.startOf('day'), 'days');

  if (daysDifference === 0) {
    return 'Due Today';
  }

  if (daysDifference === 1) {
    return `${Math.abs(daysDifference)} day overdue`;
  }

  if (daysDifference > 1) {
    return `${Math.abs(daysDifference)} days overdue`;
  }

  return null;
};
