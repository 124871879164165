import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../Table';
import Button from '../../../shared/Button';
import useHttp from '../../../shared/hooks/use-http';
import { convertObjKeysToCamelCase } from '../../../../helpers/utils';
import setRequestParams from './set-request-params';
import AddEditStaffMemberFacilityModal from './AddEditStaffMemberFacilityModal';
import DeleteFacilityConfirmationModal from './DeleteFacilityConfirmationModal';

const TABLE_HEADERS = [
  { name: 'Staff', showColumn: true, isClickable: true, actionName: 'user' },
  { name: 'Facilities', showColumn: true, isClickable: true, actionName: 'locations' },
  { name: 'Appointment Types', showColumn: true },
  { name: 'Action', showColumn: true, actionColumn: true },
];

function StaffMemberFacilitiesTab({ filters, providersData, facilitiesData, appointmentTypesData, authenticityToken }) {
  const { isLoading, sendRequest } = useHttp();
  const [staffMemberFacilities, setStaffMemberFacilities] = useState();
  const [sort, setSort] = useState();
  const [isFacilityModalOpen, setIsFacilityModalOpen] = useState(false);
  const [isDeleteFacilityModalOpen, setIsDeleteFacilityModalOpen] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState();

  const fetchStaffMemberFacilities = useCallback(
    async (pageNumber = 1) => {
      const { page, user, location_ids, state_ids, appointment_types, provider_ids, sorted_by } = setRequestParams(
        pageNumber,
        filters,
        sort
      );

      try {
        const response = await sendRequest('/staff/staff_member_facilities', {
          params: { page, user, location_ids, state_ids, appointment_types, staff_member_ids: provider_ids, sorted_by },
        });

        setStaffMemberFacilities({
          page,
          ...convertObjKeysToCamelCase(response),
        });
      } catch (error) {
        window.Sentry.captureException(error);
      }
    },
    [sendRequest, filters, sort]
  );

  useEffect(() => {
    fetchStaffMemberFacilities();
  }, [fetchStaffMemberFacilities]);

  const findFacilityById = (id) =>
    staffMemberFacilities.data.find((staffMemberFacility) => staffMemberFacility.id === id);

  const handleEditRowClick = (id) => {
    const facility = findFacilityById(id);
    setSelectedFacility(facility);
    setIsFacilityModalOpen(true);
  };

  const handleDeleteRowClick = (id) => {
    const facility = findFacilityById(id);
    setSelectedFacility(facility);
    setIsDeleteFacilityModalOpen(true);
  };

  const handleSort = (id) =>
    setSort((prevState) => ({ id, desc: prevState && prevState.id === id ? !prevState.desc : true }));

  const handleFacilityModalClose = () => {
    setSelectedFacility(null);
    setIsFacilityModalOpen(false);
  };

  const handleDeleteFacilityModalClose = () => {
    setSelectedFacility(null);
    setIsDeleteFacilityModalOpen(false);
  };

  const filteredFacilities = facilitiesData?.filter((facility) => facility.value !== '*') || [];

  return (
    <>
      <div className="mb-4 flex justify-end">
        <Button isSecondary onClick={() => setIsFacilityModalOpen(true)}>
          Create new record
        </Button>
      </div>
      <div className="border border-gray-300 shadow rounded-lg overflow-hidden bg-white">
        <Table
          tableType="staffMemberFacilitiesTable"
          paginationVariant="numbered"
          showShadow={false}
          currentPage={staffMemberFacilities?.meta?.currentPage || 1}
          tableColumnHeaders={TABLE_HEADERS}
          tableRows={staffMemberFacilities?.data || []}
          pageChange={fetchStaffMemberFacilities}
          totalCountRecords={staffMemberFacilities?.meta?.totalCount}
          onClickEditRow={handleEditRowClick}
          onDeleteButtonClick={handleDeleteRowClick}
          displayPaginationStartingElementIndex={false}
          headerButtonClick={handleSort}
          currentSort={sort || {}}
          loading={isLoading}
          stickyActionColumn
          enableHidingShadowInStickyColumn
        />
      </div>
      <AddEditStaffMemberFacilityModal
        isOpen={isFacilityModalOpen}
        onClose={handleFacilityModalClose}
        onSave={fetchStaffMemberFacilities}
        providersData={providersData}
        facilitiesData={filteredFacilities}
        appointmentTypesData={appointmentTypesData}
        staffMemberFacility={selectedFacility}
        authenticityToken={authenticityToken}
      />
      <DeleteFacilityConfirmationModal
        isOpen={isDeleteFacilityModalOpen}
        onClose={handleDeleteFacilityModalClose}
        onSave={fetchStaffMemberFacilities}
        staffMemberFacility={selectedFacility}
        authenticityToken={authenticityToken}
      />
    </>
  );
}

StaffMemberFacilitiesTab.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  providersData: PropTypes.array.isRequired,
  facilitiesData: PropTypes.array.isRequired,
  appointmentTypesData: PropTypes.array.isRequired,
};

export default StaffMemberFacilitiesTab;
