import { useState, useEffect } from 'react';

const useAddDocumentation = ({ onSelectedNotesChanged }) => {
  const [selectedNotes, setSelectedNotes] = useState([]);

  const [isAddAddendumModalOpen, setIsAddAddendumModalOpen] = useState(false);
  const [isCoSignModalOpen, setIsCoSignModalOpen] = useState(false);

  useEffect(() => {
    onSelectedNotesChanged?.(selectedNotes);
  }, [selectedNotes, onSelectedNotesChanged]);

  const addCoSignerToNote = (selectedNote, signedAt, signedBy, qualifications) => ({
    ...selectedNote,
    coSigners: [...selectedNote.coSigners, { signedAt, signedBy, qualifications }],
  });

  const handleChartingNoteCoSign = ({ chartingNoteIds, signedAt, signedBy, qualifications }) => {
    setSelectedNotes((prevState) =>
      prevState.map((selectedNote) =>
        chartingNoteIds.includes(selectedNote.id)
          ? addCoSignerToNote(selectedNote, signedAt, signedBy, qualifications)
          : selectedNote
      )
    );
  };
  const handleChartingNoteCoSignClick = () => setIsCoSignModalOpen(true);

  const handleAddAddendumClick = () => setIsAddAddendumModalOpen(true);

  const addAddendumToNote = (selectedNote, addendum, createdAt, createdBy, qualifications) => {
    const addendumObject = { content: addendum, createdAt, createdBy, qualifications };

    return {
      ...selectedNote,
      addendums: selectedNote?.addendums ? [...selectedNote.addendums, addendumObject] : [addendumObject],
    };
  };

  const handleChartingNoteAddAddendum = ({ chartingNoteIds, addendum, createdAt, createdBy, qualifications }) => {
    setSelectedNotes((prevState) =>
      prevState.map((selectedNote) =>
        chartingNoteIds.includes(selectedNote.id)
          ? addAddendumToNote(selectedNote, addendum, createdAt, createdBy, qualifications)
          : selectedNote
      )
    );
  };

  const handleChartingNoteDelete = ([noteId]) =>
    setSelectedNotes((prevState) => prevState.filter((note) => note.id !== noteId));

  const handleChartingNoteLockAndSign = ({ chartingNoteIds, lockedAndSignedBy, lockedAt }) => {
    setSelectedNotes((prevState) =>
      prevState.map((selectedNote) =>
        chartingNoteIds.includes(selectedNote.id)
          ? {
              ...selectedNote,
              lockedAt: lockedAt?.[selectedNote?.user?.id],
              lockedBy: lockedAndSignedBy?.[selectedNote?.user?.id],
            }
          : selectedNote
      )
    );
  };

  const lockedAndSignedChartingNotes = selectedNotes.filter((note) => note.lockedAt && note.hasPermissionToEdit);
  const hasPermissionToEditSomeChartingNotes = selectedNotes.some((note) => note.hasPermissionToEdit);

  return {
    selectedNotes,
    setSelectedNotes,
    isCoSignModalOpen,
    setIsCoSignModalOpen,
    isAddAddendumModalOpen,
    setIsAddAddendumModalOpen,
    handleChartingNoteCoSign,
    handleChartingNoteCoSignClick,
    handleAddAddendumClick,
    handleChartingNoteAddAddendum,
    handleChartingNoteDelete,
    handleChartingNoteLockAndSign,
    lockedAndSignedChartingNotes,
    hasPermissionToEditSomeChartingNotes,
  };
};

export default useAddDocumentation;
