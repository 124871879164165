/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoCard from './InfoCard';
import LastSynced from './LastSynced';
import MemberDetailsRow, { MemberDetailsRowPropTypes } from './MemberDetailsRow';
import OpenCloseIcon from '../shared/OpenCloseIcon';

function MemberDetailsBox({ componentType, componentData, updatedAt, isInitiallyExpanded, headerLinks }) {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

  return (
    <div className="flex flex-col gap-2">
      <InfoCard classNames="p-4 bg-blue-100" childrenClassNames="flex flex-col gap-4">
        <div className="flex gap-2 justify-between items-center">
          <span className="text-sm font-medium uppercase">{componentType}</span>
          <div className="flex gap-3 items-center">
            {headerLinks.map(({ url, label, openInNewTab, onClick, icon }) =>
              url ? (
                <a
                  key={label}
                  href={url}
                  onClick={onClick}
                  target={openInNewTab ? '_blank' : '_top'}
                  className="text-teal-800 font-semibold py-1 px-4 uppercase border-2 border-teal-700 rounded-3xl flex items-center gap-2"
                  rel="noreferrer"
                >
                  {icon}
                  {label}
                </a>
              ) : (
                <button
                  key={label}
                  type="button"
                  onClick={onClick}
                  className="text-teal-800 font-semibold py-1 px-4 uppercase border-2 border-teal-700 rounded-3xl flex items-center gap-2"
                >
                  {icon}
                  {label}
                </button>
              )
            )}
            <OpenCloseIcon isOpen={isExpanded} setOpenStatus={setIsExpanded} />
          </div>
        </div>

        {isExpanded && (
          <div className="flex flex-col gap-2">
            {!componentData?.length ? (
              <p className="text-center text-xs">No data to display</p>
            ) : (
              componentData.map((row) =>
                row?.items ? (
                  <div className="bg-white rounded border border-gray-400" key={row.key}>
                    {row?.items.map((item, i) => (
                      <div key={i} className="flex flex-col gap-2 p-2 border-b border-gray-400 last:border-b-0">
                        {Array.isArray(item.value) ? (
                          item.value.map((itemValue, itemValueIndex) => (
                            <MemberDetailsRow key={itemValueIndex} {...itemValue} />
                          ))
                        ) : (
                          <MemberDetailsRow key={item.key} {...item} />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <MemberDetailsRow key={row.key} {...row} />
                )
              )
            )}
          </div>
        )}
      </InfoCard>
      {updatedAt && <LastSynced dateTime={updatedAt} />}
    </div>
  );
}

MemberDetailsBox.propTypes = {
  isInitiallyExpanded: PropTypes.bool,
  headerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string,
      icon: PropTypes.element,
      onClick: PropTypes.func,
      openInNewTab: PropTypes.bool,
    })
  ),
  componentType: PropTypes.oneOf(Object.values(MemberDetailsRowPropTypes)).isRequired,
  componentData: PropTypes.arrayOf(MemberDetailsRowPropTypes).isRequired,
  updatedAt: PropTypes.string.isRequired,
};

MemberDetailsBox.defaultProps = {
  isInitiallyExpanded: true,
  headerLinks: [],
};

export default MemberDetailsBox;
