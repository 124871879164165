import moment from 'moment';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';

function formatDateAlgorithm({ date }) {
  if (!date) {
    return null;
  }

  const [dateWithOffset] = convertToSupportedTimeZone(date);
  return dateWithOffset.format('M/D/YY');
}

function dateDifferenceInDaysAlgorithm({ from, to }) {
  if (!from && !to) {
    return null;
  }

  const fromDate = moment(from ?? new Date()).startOf('day');
  const toDate = moment(to ?? new Date()).startOf('day');

  return Math.abs(toDate.diff(fromDate, 'days'));
}

function dateDifferenceInWeeksAlgorithm({ from, to }) {
  if (!from && !to) {
    return null;
  }

  const fromDate = moment(from ?? new Date()).startOf('day');
  const toDate = moment(to ?? new Date()).startOf('day');

  return Math.ceil(toDate.diff(fromDate, 'weeks', true));
}

export const MEMBER_DETAILS_ALGORITHM = {
  FORMAT_DATE: 'FORMAT_DATE',
  DATE_DIFFERENCE_IN_DAYS: 'DATE_DIFFERENCE_IN_DAYS',
  DATE_DIFFERENCE_IN_WEEKS: 'DATE_DIFFERENCE_IN_WEEKS',
};

// eslint-disable-next-line import/prefer-default-export
export function triggerAlgorithm(algorithm, parameters) {
  switch (algorithm) {
    case MEMBER_DETAILS_ALGORITHM.FORMAT_DATE: {
      return formatDateAlgorithm(parameters);
    }

    case MEMBER_DETAILS_ALGORITHM.DATE_DIFFERENCE_IN_DAYS: {
      return dateDifferenceInDaysAlgorithm(parameters);
    }

    case MEMBER_DETAILS_ALGORITHM.DATE_DIFFERENCE_IN_WEEKS: {
      return dateDifferenceInWeeksAlgorithm(parameters);
    }

    default: {
      return null;
    }
  }
}
